"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _icons = require('../../icons');
var _appbar = require('../appbar');
var _toolbar = require('../toolbar');
var _iconbutton = require('../iconbutton');
var _drawer = require('../drawer');
var _collapse = require('../collapse');
var _box = require('../box');
var _divider = require('../divider');
var _list = require('../list');
var _listitem = require('../listitem');
var _listitembutton = require('../listitembutton');
var _listitemicon = require('../listitemicon');
var _listitemtext = require('../listitemtext');
var _container = require('../container');
var _material = require('@mui/material');
var _balmylogolight = require('../../assets/balmy-logo-light'); var _balmylogolight2 = _interopRequireDefault(_balmylogolight);
var _balmylogodark = require('../../assets/balmy-logo-dark'); var _balmylogodark2 = _interopRequireDefault(_balmylogodark);
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _optionsmenu = require('../options-menu');
var _colors = require('../../theme/colors');
var _containerbox = require('../container-box');
var _isUndefined = require('lodash/isUndefined'); var _isUndefined2 = _interopRequireDefault(_isUndefined);
var SectionType = /* @__PURE__ */ function(SectionType2) {
  SectionType2["divider"] = "divider";
  SectionType2["link"] = "link";
  SectionType2["group"] = "group";
  return SectionType2;
}(SectionType || {});
const drawerWidthLg = 240;
const drawerWidthSm = 200;
const StyledIconToolbar = _styledcomponents2.default.call(void 0, _toolbar.Toolbar)`
  ${(_ref) => {
  let {
    theme: {
      spacing
    }
  } = _ref;
  return `
    padding: 0px ${spacing(6)};
  `;
}}
`;
const StyledListItemButton = _styledcomponents2.default.call(void 0, _listitembutton.ListItemButton)`
  ${(_ref2) => {
  let {
    theme: {
      spacing,
      palette
    }
  } = _ref2;
  return `
    padding: ${spacing(3)} ${spacing(4)};
    color: ${_colors.colors[palette.mode].typography.typo3};
    &.Mui-selected {
      background-color: inherit;
      color: ${_colors.colors[palette.mode].accent.primary}
    }
    &.Mui-selected:hover {
      background-color: inherit;
      color: ${_colors.colors[palette.mode].accent.primary}
    }
    &:hover {
      background-color: ${_colors.colors[palette.mode].background.emphasis};
      color: ${_colors.colors[palette.mode].accent.accent600};
      border-radius: ${spacing(2)};
    }
  `;
}}
`;
const StyledListItemIcon = _styledcomponents2.default.call(void 0, _listitemicon.ListItemIcon)`
  ${(_ref3) => {
  let {
    theme: {
      spacing
    }
  } = _ref3;
  return `
    color: inherit;

    min-width: 0;
    margin-right: ${spacing(3)};
  `;
}}
`;
const StyledDrawerContainer = _styledcomponents2.default.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const StyledDrawerFooterContainer = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox).attrs({
  gap: 12,
  flexDirection: "column"
})`
  ${(_ref4) => {
  let {
    theme: {
      space,
      palette: {
        mode
      }
    }
  } = _ref4;
  return `
    margin-bottom: ${space.s05};
    margin-top: auto;
    color: ${_colors.colors[mode].typography.typo3}
  `;
}}
`;
const AppBarRightContainer = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;
const StyledPromotedBannerContainer = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox).attrs({
  justifyContent: "center"
})`
  ${(_ref5) => {
  let {
    theme: {
      spacing
    }
  } = _ref5;
  return `
    padding: 0 ${spacing(4)};
  `;
}}
`;
const BuiltListItem = (_ref6) => {
  let {
    section,
    isSelected,
    showChevron,
    isOpen,
    onClick
  } = _ref6;
  return /* @__PURE__ */ _react2.default.createElement(_listitem.ListItem, {
    key: section.key,
    disablePadding: true
  }, /* @__PURE__ */ _react2.default.createElement(StyledListItemButton, {
    selected: isSelected,
    onClick: (evt) => onClick(evt.ctrlKey || evt.metaKey)
  }, /* @__PURE__ */ _react2.default.createElement(StyledListItemIcon, null, section.icon), /* @__PURE__ */ _react2.default.createElement(_listitemtext.ListItemText, {
    primary: section.label,
    primaryTypographyProps: {
      variant: "bodySmallRegular",
      color: "inherit"
    }
  }), section.endContent, showChevron && /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, isOpen ? /* @__PURE__ */ _react2.default.createElement(_icons.ExpandLessIcon, null) : /* @__PURE__ */ _react2.default.createElement(_icons.ExpandMoreIcon, null))));
};
const CollapsableItems = (_ref7) => {
  let {
    section,
    selectedSection,
    onSectionClick
  } = _ref7;
  const [open, setOpen] = _react.useState.call(void 0, );
  const {
    options,
    ...sectionWithoutOptions
  } = section;
  const isOpen = _isUndefined2.default.call(void 0, open) ? !!_optionalChain([options, 'optionalAccess', _ => _.find, 'call', _2 => _2((option) => option.key === selectedSection)]) : open;
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(BuiltListItem, {
    section: sectionWithoutOptions,
    isSelected: section.key === selectedSection || !!_optionalChain([section, 'access', _3 => _3.activeKeys, 'optionalAccess', _4 => _4.includes, 'call', _5 => _5(selectedSection)]),
    showChevron: true,
    isOpen,
    onClick: () => setOpen((oldSetOpen) => !oldSetOpen)
  }), /* @__PURE__ */ _react2.default.createElement(_collapse.Collapse, {
    in: isOpen,
    timeout: "auto",
    unmountOnExit: true
  }, /* @__PURE__ */ _react2.default.createElement(_list.List, {
    dense: true,
    disablePadding: true,
    sx: (_ref8) => {
      let {
        spacing
      } = _ref8;
      return {
        padding: `0 ${spacing(3)}`
      };
    }
  }, _optionalChain([options, 'optionalAccess', _6 => _6.map, 'call', _7 => _7((subSection) => buildItem(subSection, selectedSection, onSectionClick))]))));
};
const StyledGroupContainer = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox).attrs({
  gap: 2,
  flexDirection: "column"
})``;
const StyledDrawerLinksContainer = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox).attrs({
  gap: 4,
  flexDirection: "column"
})`
  ${(_ref9) => {
  let {
    theme: {
      spacing
    }
  } = _ref9;
  return `
    padding: ${spacing(8)} ${spacing(4)};
  `;
}}
`;
const StyledGroupTitle = _styledcomponents2.default.call(void 0, _material.Typography).attrs({
  variant: "labelRegular"
})`
  ${(_ref10) => {
  let {
    theme: {
      palette: {
        mode
      }
    }
  } = _ref10;
  return `
    color: ${_colors.colors[mode].typography.typo4};
  `;
}}
`;
const BuiltGroupItem = (_ref11) => {
  let {
    section,
    selectedSection,
    onSectionClick
  } = _ref11;
  const {
    sections
  } = section;
  return /* @__PURE__ */ _react2.default.createElement(StyledGroupContainer, null, /* @__PURE__ */ _react2.default.createElement(StyledGroupTitle, null, section.label), /* @__PURE__ */ _react2.default.createElement(_list.List, {
    dense: true,
    disablePadding: true,
    sx: {
      padding: 0
    }
  }, sections.map((subSection) => buildItem(subSection, selectedSection, onSectionClick))));
};
const buildItem = (section, selectedSection, onSectionClick) => {
  if (section.type === SectionType.divider) {
    return /* @__PURE__ */ _react2.default.createElement(_divider.DividerBorder2, null);
  }
  if (section.type === SectionType.group) {
    return /* @__PURE__ */ _react2.default.createElement(BuiltGroupItem, {
      section,
      selectedSection,
      onSectionClick
    });
  }
  if (section.options) {
    return /* @__PURE__ */ _react2.default.createElement(CollapsableItems, {
      key: section.key,
      section,
      selectedSection,
      onSectionClick
    });
  }
  return /* @__PURE__ */ _react2.default.createElement(BuiltListItem, {
    section,
    isSelected: section.key === selectedSection || !!_optionalChain([section, 'access', _8 => _8.activeKeys, 'optionalAccess', _9 => _9.includes, 'call', _10 => _10(selectedSection)]),
    onClick: (openInNewTab) => onSectionClick(section, openInNewTab),
    key: section.key
  });
};
const buildDrawer = (_ref12) => {
  let {
    sections,
    selectedSection,
    onSectionClick
  } = _ref12;
  const items = [];
  let i = 0;
  while (i < sections.length) {
    let section = sections[i];
    if (section.type === SectionType.group) {
      const links = [];
      while (i < sections.length && sections[i].type === SectionType.group) {
        links.push(buildItem(section, selectedSection, onSectionClick));
        i++;
        section = sections[i];
      }
      items.push(/* @__PURE__ */ _react2.default.createElement(_list.List, {
        dense: true,
        sx: {
          padding: 0
        }
      }, links));
    } else if (sections[i].type === SectionType.link) {
      const links = [];
      while (i < sections.length && sections[i].type === SectionType.link) {
        links.push(buildItem(section, selectedSection, onSectionClick));
        i++;
        section = sections[i];
      }
      items.push(/* @__PURE__ */ _react2.default.createElement(_list.List, {
        dense: true,
        sx: {
          padding: 0
        }
      }, links));
    } else if (section.type === SectionType.divider) {
      items.push(buildItem(section, selectedSection, onSectionClick));
      i++;
    }
  }
  return items;
};
const StyledBeamerContainer = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox)`
  .beamer_icon.active {
    top: -5px !important;
    right: -8px !important;
  }
`;
const Navigation = (_ref13) => {
  let {
    children,
    sections,
    selectedSection,
    onSectionClick,
    settingsOptions,
    helpOptions,
    extraHeaderTools,
    onClickBrandLogo,
    headerContent,
    promotedBanner,
    shareReferralLink
  } = _ref13;
  const [mobileOpen, setMobileOpen] = _react.useState.call(void 0, false);
  const {
    palette: {
      mode
    },
    spacing,
    breakpoints
  } = _material.useTheme.call(void 0, );
  const isDownLg = _material.useMediaQuery.call(void 0, breakpoints.down("lg"));
  const isDownMd = _material.useMediaQuery.call(void 0, breakpoints.down("md"));
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleSectionClick = (section, openInNewTab) => {
    onSectionClick(section, openInNewTab);
    setMobileOpen(false);
  };
  const drawerWidth = isDownLg ? drawerWidthSm : drawerWidthLg;
  const drawerLinks = buildDrawer({
    sections,
    selectedSection,
    onSectionClick: handleSectionClick
  });
  const iconProps = {
    cursor: "pointer",
    onClick: onClickBrandLogo,
    size: "110px"
  };
  const icon = mode === "light" ? /* @__PURE__ */ _react2.default.createElement(_balmylogolight2.default, iconProps) : /* @__PURE__ */ _react2.default.createElement(_balmylogodark2.default, iconProps);
  const drawer = /* @__PURE__ */ _react2.default.createElement(StyledDrawerContainer, null, /* @__PURE__ */ _react2.default.createElement(StyledIconToolbar, {
    sx: {
      padding: `${spacing(4)} ${spacing(6)}`
    }
  }, icon), headerContent, /* @__PURE__ */ _react2.default.createElement(StyledDrawerLinksContainer, null, drawerLinks, isDownMd && shareReferralLink), /* @__PURE__ */ _react2.default.createElement(StyledDrawerFooterContainer, null, /* @__PURE__ */ _react2.default.createElement(StyledPromotedBannerContainer, null, promotedBanner), /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    gap: 5,
    justifyContent: "center",
    alignItems: "center"
  }, /* @__PURE__ */ _react2.default.createElement(_material.Link, {
    underline: "none",
    target: "_blank",
    href: "https://github.com/balmy-protocol"
  }, /* @__PURE__ */ _react2.default.createElement(_icons.GithubIcon, {
    sx: {
      color: _colors.colors[mode].typography.typo3
    }
  })), /* @__PURE__ */ _react2.default.createElement(_material.Link, {
    underline: "none",
    target: "_blank",
    href: "https://twitter.com/balmy_xyz"
  }, /* @__PURE__ */ _react2.default.createElement(_icons.TwitterIcon, {
    sx: {
      color: _colors.colors[mode].typography.typo3
    }
  })), /* @__PURE__ */ _react2.default.createElement(_material.Link, {
    underline: "none",
    target: "_blank",
    href: "http://discord.balmy.xyz"
  }, /* @__PURE__ */ _react2.default.createElement(_icons.DiscordIcon, {
    sx: {
      color: _colors.colors[mode].typography.typo3
    }
  })))));
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(_appbar.AppBar, {
    position: "fixed",
    sx: {
      width: {
        md: `calc(100% - ${drawerWidth}px)`
      },
      ml: {
        md: `${drawerWidth}px`
      },
      backgroundColor: _colors.colors[mode].background.secondary
    }
  }, /* @__PURE__ */ _react2.default.createElement(_container.Container, null, /* @__PURE__ */ _react2.default.createElement(_toolbar.Toolbar, null, /* @__PURE__ */ _react2.default.createElement(_iconbutton.IconButton, {
    color: "inherit",
    "aria-label": "open drawer",
    edge: "start",
    onClick: handleDrawerToggle,
    sx: {
      mr: 2,
      display: {
        md: "none"
      }
    }
  }, /* @__PURE__ */ _react2.default.createElement(_icons.MenuIcon, {
    sx: {
      color: _colors.colors[mode].accentPrimary
    }
  })), /* @__PURE__ */ _react2.default.createElement(AppBarRightContainer, {
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
    gap: 2
  }, /* @__PURE__ */ _react2.default.createElement(_optionsmenu.OptionsMenu, {
    options: helpOptions,
    mainDisplay: /* @__PURE__ */ _react2.default.createElement(StyledBeamerContainer, {
      className: "beamer-whats-new",
      "data-beamer-click": "false"
    }, /* @__PURE__ */ _react2.default.createElement(_icons.HelpIcon, null))
  }), /* @__PURE__ */ _react2.default.createElement(_optionsmenu.OptionsMenu, {
    options: settingsOptions,
    mainDisplay: /* @__PURE__ */ _react2.default.createElement(_icons.CogIcon, null)
  }), extraHeaderTools)))), /* @__PURE__ */ _react2.default.createElement(_box.Box, {
    component: "nav",
    sx: {
      width: {
        md: drawerWidth
      },
      flexShrink: {
        md: 0
      }
    }
  }, /* @__PURE__ */ _react2.default.createElement(_drawer.Drawer, {
    variant: "temporary",
    open: mobileOpen,
    onClose: handleDrawerToggle,
    ModalProps: {
      keepMounted: true
      // Better open performance on mobile.
    },
    sx: {
      display: {
        xs: "block",
        md: "none"
      },
      "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        width: drawerWidth,
        backgroundColor: _colors.colors[mode].background.secondary
      }
    }
  }, drawer), /* @__PURE__ */ _react2.default.createElement(_drawer.Drawer, {
    variant: "permanent",
    sx: {
      display: {
        xs: "none",
        md: "block"
      },
      "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        width: drawerWidth,
        backgroundColor: _colors.colors[mode].background.secondary
      }
    },
    open: true
  }, drawer)), /* @__PURE__ */ _react2.default.createElement(_box.Box, {
    component: "main",
    sx: {
      flexGrow: 1,
      display: "flex !important",
      flexDirection: "column",
      p: 3,
      width: {
        md: `calc(100% - ${drawerWidth}px)`
      },
      alignSelf: {
        md: "flex-end"
      },
      padding: 0,
      maxWidth: "100%",
      alignItems: "center"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_toolbar.Toolbar, null), children));
};



exports.Navigation = Navigation; exports.SectionType = SectionType;
